import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})

/**
 * Switcher component
 */
export class SwitcherComponent implements OnInit {

  isVisible = false;

  constructor() { }

  ngOnInit(): void { }
  
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.windowScroll();
  }

  windowScroll() {
    const backToTopButton = document.getElementById("back-to-top");
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      backToTopButton.style.display = "inline";
    } else {
      backToTopButton.style.display = "none";
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  /**
   * Onclick color change
   * @param theme theme color
   */
  setTheme(theme) {
    document
      .getElementById('color-opt')
      .setAttribute('href', 'assets/css/colors/' + theme + '.css');
  }

  toggleSwitcher() {
    this.isVisible = !this.isVisible;
  }

}
