<!-- START FOOTER -->
<section class="section footer">
  <!-- <div class="bg-overlay"></div> -->
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mt-4 pt-2 text-center">
            <img src="./assets/images/logo-portal.png" alt="logo portal de compras públicas"
            class="logo-footer">
        </div>
        <div class="mt-4 pt-2 text-center">
          <p class="text-muted mb-0 footer-text1">
            Ao fornecer o seu e-email e clicar no botão, você estará concordando
            em receber o Alerta de Licitações, bem como novidades do Portal de
            Compras Públicas. Você terá a possibilidade de se descadastrar do
            nosso serviço de e-mail a qualquer momento. Caso tenha alguma
            dúvida, entre em contato e verifique nossos termos de políticas de
            privacidade
          </p>
          <p class="copy-rights text-muted mb-0 footer-text2">
            © {{ year }} - Portal de compras públicas. Todos os direitos
            Reservados. Ecustomize Consultoria em Software S.A. -
            09.397.355/0001-30
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- END FOOTER -->
