import { Component, HostListener, Input } from '@angular/core';
import { Payload } from 'src/app/core/payload';
import { Bids } from 'src/app/core/bids';
import { RequestsService } from 'src/app/core/requests.service';

@Component({
  selector: 'app-index2',
  templateUrl: './index2.component.html',
  styleUrls: ['./index2.component.scss'],
})
export class Index2Component {
  bids: Bids[] = [];
  isLoading = false;
  didRequest = false;
  showItems = false;
  totalResults = 0;
  currentPayload: Payload = {
    item: '',
    uf: '',
    entityType: "2",
    modality: null,
    filter: 1,
    page: 1,
    eventType: 'form',
  };
  @Input() onPageChange: any;
  finalPage = false;
  isMobileScreen: boolean;
  currentSection = 'home';

  constructor(
    private requestService: RequestsService
  ) {}

  private requestBids(payload: Payload): Promise<any> {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      this.requestService.submitSearchForm(payload).subscribe(
        (data: any) => {
          this.isLoading = false;
          resolve(data);
        },
        (err) => {
          this.isLoading = false;
          reject();
        }
      );
    });
  }

  async onSubmit(payload: Payload) {
    this.didRequest = true;
    this.finalPage = false;
  
    if (payload.eventType === 'form') {
      this.bids = [];
      this.showItems = false;
      this.totalResults = 0;
      payload.page = 1; 
      this.currentPayload = {...payload}; 
      this.isLoading = true;
      setTimeout(() => {
        this.scrollToSearchedItems();
      }, 100)
    } else {
      this.currentPayload = {...payload};
    }
  
    const response = await this.requestBids(this.currentPayload);
    if (response.bids.length === 0) {
      this.finalPage = true;
      if (this.currentPayload.page === 1) {
        this.bids = [];
      }
    } else {
      this.bids = response.bids;
      this.totalResults = response.totalResults;
      this.showItems = true;
    }
    this.isLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    this.isMobileScreen = window.innerWidth < 1024;
  }

  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (
      document.body.scrollTop > 40 ||
      document.documentElement.scrollTop > 40
    ) {
      navbar.style.backgroundColor = '#1a1a1a';
      navbar.style.padding = '15px 0px';
    } else {
      navbar.style.backgroundColor = '';
      navbar.style.padding = '20px';
    }
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  handleLogoClick() {
    window.scroll(0, 0);
  }

  private scrollToSearchedItems(){
    const element = document.getElementById('searched-items');
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset; 
      const offsetPosition = elementPosition - 100;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }
}

