<form
  method="post"
  [formGroup]="form"
  class="obrigado1-search-container"
  (ngSubmit)="onSubmit()"
>
  <div class="input-container">
    <div class="text-search-container">
      <label for="item-search-input" class="text-search-title"> Item* </label>
      <input
        title="Item"
        id="item-search-input"
        type="text"
        name="item"
        class="item-search-input"
        placeholder="Termo ou palavra-chave do que você quer vender para o governo"
        (keydown.enter)="eventKeyListener()"
        required
        formControlName="item"
      />
    </div>
    <div class="select-container">
      <label for="select-input" class="select-search-title"> UF </label>
      <ng-select
        required
        placeholder="Todos"
        name="estado"
        [items]="estados"
        bindLabel="estado"
        class="select-input"
        formControlName="uf"
        [openOnEnter]="false"
        [clearable]="false"
        (keydown.enter)="eventKeyListener()"
      >
      </ng-select>
    </div>
  </div>
  <div class="advanced-item-container" *ngIf="showAdvancedSearch">
    <div class="entidade-container">
      <label for="select-input" class="select-search-title">Buscar por:</label>
      <ng-select
        placeholder="Objeto e item"
        name="entidade"
        [items]="tipoEntidadeOptions"
        bindLabel="label"
        class="select-input"
        [openOnEnter]="false"
        [clearable]="false"
        formControlName="entidade"
        (keydown.enter)="eventKeyListener()"
      >
      </ng-select>
    </div>
    <div class="modalidade-container">
      <label for="select-input" class="select-search-title">Modalidade:</label>
      <ng-select
        placeholder="Todas"
        name="modalidade"
        [items]="modalidadeOptions"
        bindLabel="label"
        class="select-input"
        (keydown.enter)="eventKeyListener()"
        [openOnEnter]="false"
        [clearable]="false"
        formControlName="modalidade"
      >
      </ng-select>
    </div>
    <div class="modalidade-container">
      <label for="select-input" class="select-search-title"> Filtro: </label>
      <ng-select
        placeholder="Todos os processos"
        name="filtro"
        [items]="statusOptions"
        bindLabel="label"
        formControlName="filtro"
        class="select-input"
        (keydown.enter)="eventKeyListener()"
        [openOnEnter]="false"
        [clearable]="false"
      >
      </ng-select>
    </div>
  </div>
  <div class="button-container">
    <div class="advanced-search-container" *ngIf="!showAdvancedSearch">
      <button
        type="button"
        class="advanced-search-button"
        (click)="toggleAdvancedSearch()"
      >
        Abrir busca avançada
        <img
          src="../../../assets/images/advanced-icon.png"
          class="symbol-advanced-search"
          alt="símbolo de pesquisa avançada"
        />
      </button>
    </div>
    <div class="advanced-search-container" *ngIf="showAdvancedSearch">
      <button
        type="button"
        class="advanced-search-button"
        (click)="toggleAdvancedSearch()"
      >
        Fechar busca avançada
        <img
          src="../../../assets/images/close-adv.png"
          class="symbol-advanced-search"
          alt="símbolo de fechar pesquisa avançada"
        />
      </button>
    </div>
    <div>
      <button
        type="submit"
        class="obrigado-search-button"
        id="button-submit"
        [disabled]="form.invalid"
      >
        PESQUISAR
      </button>
    </div>
  </div>
</form>
