import { Categorias } from './categorias';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { CategoriasGptRequest } from './categoriasGptRequest';
import { FornecedorDto } from './fornecedordto';
import { CategoriasOut } from './categoriasout';
import { environment } from 'src/environments/environment.prod';
import { FormGroup } from '@angular/forms';
import { TokenData } from './tokenData';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  private searchFormSubmitted = new EventEmitter<FormGroup>();
  private tokenBackend = null;

  constructor(private http: HttpClient) {}

  getEstados(): Observable<any> {
    return this.http.get<any>('assets/estados-cidades.json');
  }

  getStatesSearchFilter(): Observable<any> {
    return this.http.get<any>('assets/city-and-states.json').pipe(
      map((data: any) => {
        const estadoUf = data.estados.map((estado: any) => estado.sigla);

        estadoUf.unshift('Todos');

        return estadoUf;
      })
    );
  }

  getMunicipios(): Observable<any> {
    return this.http.get<any>('assets/municipios.json');
  }

  getCategorias(): Observable<Categorias[]> {
    return this.http.get<Categorias[]>('assets/categorias-materiais-in.json');
  }

  getCategoriasOut(): Observable<CategoriasOut[]> {
    return this.http.get<CategoriasOut[]>(
      'assets/categorias-materiais-out.json'
    );
  }

  getCategoriasGpt(
    categoriasGptRequest: CategoriasGptRequest
  ): Observable<any> {
    return this.http.post<any>(
      environment.URLGetCompletion,
      categoriasGptRequest
    );
  }

  postCadastro(fornecedorDto: FornecedorDto) {
    return this.http.post<FornecedorDto>(
      environment.URLFornecedorRegister,
      fornecedorDto
    );
  }

  submitSearchForm(payload: any): Observable<any> {
    return this.http.post<any>(
      environment.URL_BACKEND + environment.ENDPOINT_SEARCH_BACKEND,
      payload
    );
  }

  getToken(): string | null {
    return this.tokenBackend?.accessToken || null;
  }

  refreshAuthorizationToken(): void {
    const payloadAuth = {
        app: {
            name: environment.APP_NAME,
            version: environment.APP_VERSION,
        },
        secret: environment.APP_SECRET,
    };

    this.http.post<TokenData>(`${environment.URL_AUTHENTICATOR}${environment.ENDPOINT_AUTHENTICATOR}`, payloadAuth).subscribe((tokenData: TokenData) => {
        this.tokenBackend = tokenData;
    });
  }

  getSearchFormSubmittedEvent(): EventEmitter<FormGroup> {
    return this.searchFormSubmitted;
  }

  postDiscord(message: any) {
    const payload = {
      content: message,
      username: environment.UserNameWebhookDiscord,
    };

    return this.http.post<string>(environment.URLWebhookDiscord, payload);
  }
}
