import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Toastr } from 'src/app/core/toastr';
import { TypeMessageEnum } from '../type-message.enum';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss']
})
export class ToastrComponent implements OnInit {

  @ViewChild("toastrWrapperElement") toastrWrapperElement: ElementRef<Element>;
  @Input() toastrInfo: Toastr = null;
  @Input() autoDestroy: boolean = false;
  @Input() destroyOnClick: boolean = true;
  @Input() borderShape: 'square' | 'circle' = 'square';

  @Output() clearToastr: EventEmitter<any> = new EventEmitter();

  public statusClassName: string = null;
  public mensagemClassName: string = null;
  public renderToastr: boolean = true;

  private timeOut: any = null;

  constructor() { }

  ngOnInit() {
    this.initToastr();
    return;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.toastrInfo?.isFirstChange()) {
      return;
    }
    return this.initToastr();
  }

  private initToastr(): void {
    this.statusClassName = this.statusClass(this.toastrInfo?.status);
    this.mensagemClassName = this.mensagemClass(this.toastrInfo?.status);

    if (this.autoDestroy) {
      this.timeOut = setTimeout(() => this.easeOut(), 4000);
    }

    return;
  }

  private easeOut(): void {
    const element: Element = this.toastrWrapperElement
      ?.nativeElement
      .querySelector('.toastr-status');

    if (!element || element.hasAttribute('hidden')) {
      return;
    }

    this.destroy(element);

  }

  public clickEvent($event: MouseEvent): void {
    if (this.destroyOnClick) {
      return this.destroy($event.currentTarget as Element);
    }
  }

  private destroy(element: Element): void {
    element?.setAttribute('hiding', '');
    element?.addEventListener(
      'animationend',
      () => {
        element?.removeAttribute('hiding');
        element?.setAttribute('hidden', '');
        this.clearToastr.emit(this.toastrInfo);
        clearInterval(this.timeOut);
      },
      { once: true }
    );

    return;
  }

  private statusClass(status: TypeMessageEnum): string {
    const statusOptions: any = {
      [TypeMessageEnum.IMPEDITIVO]: 'impeditivo',
      [TypeMessageEnum.SUCESSO]: 'sucesso',
      [TypeMessageEnum.INFORMATIVO]: 'informativo'
    };

    return statusOptions[status] || '';
  }

  private mensagemClass(status: TypeMessageEnum): string {
    const mensagemOptions: any = {
      [TypeMessageEnum.IMPEDITIVO]: 'white',
      [TypeMessageEnum.SUCESSO]: 'white',
      [TypeMessageEnum.INFORMATIVO]: 'white'
    };

    return mensagemOptions[status] || '';
  }

}
