import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RequestsService } from 'src/app/core/requests.service';
import { Estados } from 'src/app/core/estados';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Payload } from 'src/app/core/payload';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css'],
})
export class SearchFilterComponent implements OnInit {
  estados: Estados[] = [];
  showAdvancedSearch = false;
  @Output() submitEvent: EventEmitter<Payload> = new EventEmitter();
  form!: FormGroup;

  isAdvancedSearchActive(): boolean {
    return this.showAdvancedSearch;
  }

  constructor(private requestsService: RequestsService) {}

  ngOnInit(): void {
    this.requestsService.getStatesSearchFilter().subscribe((estados) => {
      this.estados = estados;
    });

    this.form = new FormGroup({
      item: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      uf: new FormControl('Todos', [Validators.required]),
      entidade: new FormControl({ label: 'Objeto e Item', value: "2" }),
      modalidade: new FormControl('Todas'),
      filtro: new FormControl({ label: 'Todos os processos ', value: 1 }),
      page: new FormControl(1),
    });
  }

  toggleAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  onSubmit() {
    const payload: Payload = {
      item: this.form.value.item,
      uf: this.form.value.uf,
      entityType: this.form.value.entidade?.value
        ? this.form.value.entidade.value
        : 2,
      modality: this.form.value.modalidade?.value
        ? this.form.value.modalidade?.value
        : '',
      filter: this.form.value.filtro?.value
        ? this.form.value.filtro.value
        : 1,
      page: this.form.value.page,
      eventType: 'form',
    };

    this.submitEvent.emit(payload);
  }

  eventKeyListener(): void {
    if (this.form.valid) {
      this.onSubmit();
    }
  }

  tipoEntidadeOptions = [
    { label: 'Objeto', value: "0" },
    { label: 'Item', value: "1" },
    { label: 'Objeto e Item', value: "2" },
  ];

  modalidadeOptions = [
    { label: 'Todas', value: '' },
    { label: 'Pregão', value: '1' },
    { label: 'Pregão para Registro de Preço', value: '2' },
    { label: 'Dispensa', value: '3' },
    { label: 'Cotação', value: '4' },
    { label: 'Leilão', value: '5' },
    { label: 'Concorrência', value: '6' },
    { label: 'Credenciamento', value: '7' },
    { label: 'Contratação Direta', value: '8' },
    { label: 'Regime Diferenciado de Contratação', value: '9' },
    { label: 'Chamada Pública da Agricultura Familiar', value: '10' },
    { label: 'Chamamento Público', value: '11' },
    { label: 'Leilão Eletrônico', value: '12' },
    { label: 'Inexigibilidade', value: '13' },
    { label: 'Concurso', value: '14' },
    { label: 'Pregão Lei das Estatais 13.303', value: '91' },
    { label: 'Dispensa - Lei das Estatais 13.303', value: '92' },
  ];

  statusOptions = [
    { label: 'Todos os processos', value: 1 },
    { label: 'Baixa concorrência', value: 2 },
  ];
}
