import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { ServicesComponent } from './services/services.component';
import { OpcpComponent } from './opcp/opcp.component';
import { FacilidadesComponent } from './facilidades/facilidades.component';
import { ComofuncionaComponent } from './comofunciona/comofunciona.component';
import { FooterComponent } from './footer/footer.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { QuaselaComponent } from './quasela/quasela.component';
import { CardsObrigadoComponent } from './cards-obrigado/cards-obrigado.component';
import { ToastrComponent } from './toastr/toastr.component';
import { SearchedItemsComponent } from './searched-items/searched-items.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ServicesComponent,
    OpcpComponent,
    FacilidadesComponent,
    QuaselaComponent,
    CardsObrigadoComponent,
    ComofuncionaComponent,
    FooterComponent,
    SwitcherComponent,
    ScrollspyDirective,
    ToastrComponent,
    SearchedItemsComponent,
    SearchFilterComponent,
  ],
  imports: [CommonModule, CarouselModule, ScrollToModule.forRoot(), NgSelectModule, ReactiveFormsModule],
  exports: [
    ServicesComponent,
    OpcpComponent,
    FacilidadesComponent,
    QuaselaComponent,
    CardsObrigadoComponent,
    ComofuncionaComponent,
    FooterComponent,
    SwitcherComponent,
    ScrollspyDirective,
    ToastrComponent,
    SearchedItemsComponent,
    SearchFilterComponent
  ],
})
export class SharedModule {}
