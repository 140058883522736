import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Bids } from 'src/app/core/bids';
import { Payload } from 'src/app/core/payload';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-searched-items',
  templateUrl: './searched-items.component.html',
  styleUrls: ['./searched-items.component.scss'],
})
export class SearchedItemsComponent implements OnChanges {
  @Input() bids: Bids[] = [];
  @Input() totalResults = 0;
  @Input() payload: any;
  @Input() showItems = false;
  @Input() didRequest = false;
  @Input() isLoading = true;
  @Input() finalPage = false;
  @Output() submitEvent: EventEmitter<Payload> = new EventEmitter();
  loadedBids: Bids[] = [];
  showTransition = false;
  @ViewChild('loadingMoreItems', { static: false }) loadingMoreItems: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['bids'] && !changes['bids'].firstChange) {
      this.updateData();
      if (this.isLoading) {
        this.showItems = false;
        this.showTransition = false;
      }
    }

    if (changes['bids'] && changes['bids'].currentValue.length === 0 && this.didRequest) {
      this.showItems = false;
      this.showTransition = false;
      this.loadedBids = [];
    }
  }

  updateData(): void {
    if (this.bids && this.bids.length > 0) {
      this.bids.forEach((bid) => {
        bid.finalDateFormatted = this.getDateAndFormat(bid.finalDate);
        bid.modalidadeLabel = this.getModalidadeLabel(bid.cdModality);
        bid.lco = this.extractCustomInfoFromURL(bid.url);
        bid.utmUrl = this.getEnvimentUtm(bid.url);
      });

      if (this.loadedBids.length === 0 || this.payload.page === 1) {
        this.loadedBids = [];
        this.loadedBids = [...this.bids];
      } else {
        this.loadedBids.push(...this.bids);
      }
      this.showItems = true;
      setTimeout(() => {
        this.showTransition = true;
      }, 0);
    } else if (this.bids.length === 0 && this.didRequest && this.payload.page === 1) {
      this.showItems = false;
      this.loadedBids = [];
    }
  }

  getEnvimentUtm(url: string): string {
    return `${url}?utm_campaign=${environment.UTM_CAMPAIGN || ""}&utm_content=${environment.UTM_CONTENT || ""}&utm_medium=${environment.UTM_MEDIUM || ""}&utm_source=${environment.UTM_SOURCE || ""}`;
  }

  getDateAndFormat(inputData: string): string {
    const arr = inputData.split(' ');
    const dateParts = arr[0].split('/').reverse();
    const formattedDate = dateParts.join('/') + ' às ' + arr[1];
    return formattedDate;
  }

  extractCustomInfoFromURL(url: string): string {
    const parts = url.split('/');

    const subPart = parts[3];
    const untratedLco = subPart.split('-');

    untratedLco.shift();

    const tratedLco = untratedLco.slice(0, -2).join(' ');

    return tratedLco;
  }
  getModalidadeLabel(cdModality: number): string {
    const modalidadeOptions = [
      { label: 'Pregão', value: 1 },
      { label: 'Pregão para Registro de Preço', value: 2 },
      { label: 'Dispensa', value: 3 },
      { label: 'Cotação', value: 4 },
      { label: 'Leilão', value: 5 },
      { label: 'Concorrência', value: 6 },
      { label: 'Credenciamento', value: 7 },
      { label: 'Contratação Direta', value: 8 },
      { label: 'Regime Diferenciado de Contratação', value: 9 },
      { label: 'Chamada Pública da Agricultura Familiar', value: 10 },
      { label: 'Chamamento Público', value: 11 },
      { label: 'Leilão Eletrônico', value: 12 },
      { label: 'Inexigibilidade', value: 13 },
      { label: 'Concurso', value: 14 },
      { label: 'Pregão Lei das Estatais 13.303', value: 91 },
      { label: 'Dispensa - Lei das Estatais 13.303', value: 92 },
    ];

    const modalidade = modalidadeOptions.find(
      (option) => option.value === cdModality
    );

    return modalidade!.label;
  }

  loadMoreItems() {
    if (this.isLoading || this.finalPage) {
      return;
    }

    this.isLoading = true;
    this.payload.page++;
    this.payload.eventType = 'scroll';
    this.submitEvent.emit(this.payload);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const documentHeight = document.body.scrollHeight;

    if((windowHeight + scrollY) >= documentHeight - 1200 && !this.isLoading && !this.finalPage) {
      this.loadMoreItems();
    }
  }
}
