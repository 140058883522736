<!-- STRAT NAVBAR -->
<nav
  class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
  id="navbar"
  (window:scroll)="windowScroll()"
>
  <div class="container">
    <div class="navbar-container navbar-just-logo">
      <div class="logo-navbar logo-click">
        <img
          src="./assets/images/logo-portal.png"
          alt="logo portal de compras publicas"
          class="logo-portal"
          (click)="handleLogoClick()"
        />
      </div>
    </div>
  </div>
</nav>
<!-- END NAVBAR -->
<div
  appScrollspy
  [spiedTags]="['SECTION']"
  (sectionChange)="onSectionChange($event)"
>
  <!-- START HOME -->
  <div class="obrigado-background-color-box">
    <section class="section bg-obrigado" id="home">
      <div class="container">
        <div class="container-content-obrigado">
            <div class="obrigado1-text-container">
                <h1 class="obrigado1-title">
                    Não perca a chance, busque oportunidades agora.
                </h1>
                <p class="obrigado1-text">
                    A sua oportunidade de vencer uma licitação pode estar a um clique de você! Não deixe a chance passar, busque agora licitações para os produtos ou serviços que você vende!
                </p>
                <app-search-filter (submitEvent)="onSubmit($event)"></app-search-filter>
              </div>
            </div>
      </div>
    </section>
  </div>
  <div class="bg-processos">
    <app-searched-items
      id="searched-items"
      [bids]="bids"
      [totalResults]="totalResults"
      [payload]="currentPayload"
      [isLoading]="isLoading"
      [finalPage]="finalPage"
      [didRequest]="didRequest"
      [showItems]="showItems"
      (submitEvent)="onSubmit($event)"
      *ngIf="didRequest === true"
    ></app-searched-items>
    <div>
      <app-quasela></app-quasela>
      <app-cards-obrigado></app-cards-obrigado>
      <app-footer></app-footer>
      <app-switcher></app-switcher>
    </div>
  </div>
</div>
