export const environment = {
  production: true,
  URLGetProcess: 'https://landpage-cadastrosimplificado.api.portaldecompraspublicas.com.br/api',
  URLFornecedorRegister : 'https://landpage-cadastrosimplificado.api.portaldecompraspublicas.com.br/v1/fornecedor',
  URLGetCompletion : 'https://landpage-cadastrosimplificado.api.portaldecompraspublicas.com.br/api/openai',
  URLWebhookDiscord: 'https://discord.com/api/webhooks/1197508904117080135/RX9MrglV1XYzlXgmj002RBfsooMseYdadXpF4TK-uH9i5Dm1rOey455CSKaYML5VmgN0',
  UserNameWebhookDiscord: 'Magnelson - LP Status',
  URL_BACKEND: 'https://landpage-cadastrosimplificado.api.portaldecompraspublicas.com.br',
  ENDPOINT_SEARCH_BACKEND: "/v1/search",
  ENDPOINT_AUTHENTICATOR: "/v1/auth",
  URL_AUTHENTICATOR: 'https://auth.mtec.portaldecompraspublicas.com.br',
  APP_SECRET:  "",
  APP_VERSION: "",
  APP_NAME:  "",
  UTM_CONTENT: "content",
  UTM_MEDIUM: "medium",
  UTM_CAMPAIGN: "campaign",
  UTM_SOURCE: "source",
};
