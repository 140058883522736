<!-- Back to top -->
<div>
    <a (click)="scrollToTop()" class="back-to-top" id="back-to-top"> <i class="ti-angle-up"> </i> </a>
  </div>
  <!-- Style switcher -->
  <div id="style-switcher" [style.left]="isVisible ? '0px' : '-189px'">
    <div>
      <h3>Select your color</h3>
      <ul class="pattern">
        <li>
          <a class="color1" href="#" (click)="setTheme('default')"></a>
        </li>
        <li>
          <a class="color2" href="#" (click)="setTheme('pink')"></a>
        </li>
        <li>
          <a class="color3" href="#" (click)="setTheme('yellow')"></a>
        </li>
        <li>
          <a class="color4" href="#" (click)="setTheme('green')"></a>
        </li>
        <li>
          <a class="color5" href="#" (click)="setTheme('purple')"></a>
        </li>
        <li>
          <a class="color6" href="#" (click)="setTheme('light-blue')"></a>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <a href="#" class="settings" (click)="toggleSwitcher()"><i class="ti-settings ti-spin"></i></a>
    </div>
  </div>
  <!-- end Style switcher -->
  