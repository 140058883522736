import { AbstractControl, ValidationErrors } from '@angular/forms';

export class Utils {
  static validateCPF(control: AbstractControl): ValidationErrors | null {
    const cpf = control.value;
    if (!cpf) return null;

    const cpfClean = cpf.replace(/\D/g, "");

    if (cpfClean.length !== 11) return { cpfInvalid: true };

    if (/^(\d)\1*$/.test(cpfClean)) return { cpfInvalid: true };

    let sum = 0 ;

    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpfClean.charAt(i)) * (10 - i);
    }
    let remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpfClean.charAt(9))) return { cpfInvalid: true };

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpfClean.charAt(i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpfClean.charAt(10))) return { cpfInvalid: true };

    return null;
  }

  static validateCNPJ(control: AbstractControl): ValidationErrors | null {
    const cnpj = control.value;
    if (!cnpj) return null;

    const cnpjClean = cnpj.replace(/\D/g, "");
    if (cnpjClean.length !== 14) return { cnpjInvalid: true };

    if (/^(\d)\1*$/.test(cnpjClean)) return { cnpjInvalid: true };

    let sum = 0;
    let weight = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnpjClean.charAt(i), 10) * weight[i];
    }

    let remainder = (sum % 11);
    if (remainder < 2) remainder = 0;
    else remainder = 11 - remainder;
    if (remainder !== parseInt(cnpjClean.charAt(12), 10)) return { cnpjInvalid: true };

    sum = 0;
    weight.unshift(6);

    for (let i = 0; i < 13; i++) {
      sum += parseInt(cnpjClean.charAt(i), 10) * weight[i];
    }

    remainder = (sum % 11);
    if (remainder < 2) remainder = 0;
    else remainder = 11 - remainder;
    if (remainder !== parseInt(cnpjClean.charAt(13), 10)) return { cnpjInvalid: true };

    return null;
  }
}
