<div class="search-results-container">
  <div class="search-items-container" [ngClass]="{ show: showTransition }" *ngIf="!isLoading || showItems">
    <div class="search-results-header" style="align-self: flex-start">
      <h2 class="search-results-title">Resultados da pesquisa</h2>
      <p
        class="search-results-count"
        *ngIf="loadedBids.length > 0"
      >
        {{ totalResults }} resultados para a pesquisa
      </p>
      <p
        class="search-results-count"
        *ngIf="loadedBids.length === 0 && didRequest && !isLoading && payload.page === 1"
      >
        Sem resultados para {{ payload.item }}
      </p>
    </div>
    <ng-container *ngIf="loadedBids.length > 0">
      <div class="search-card" *ngFor="let result of loadedBids">
        <div class="card-header">
          <p class="status-pill">{{ result.lco }}</p>
          <p class="status-label">
            <img
              src="../../../assets/images/013-download.png"
              alt="Recebendo proposta"
              class="icon-status"
            />Recebendo propostas
          </p>
        </div>
        <div class="card-content">
          <h3 class="card-title">{{ result.item[0].product }}</h3>
          <p class="card-subtitle" *ngIf="result.item.length > 1">
            {{ result.item.length }} Itens relacionados à sua pesquisa e
            declarados neste processo…
          </p>
        </div>
        <div class="card-info">
          <div class="card-info-left">
            <div class="card-info-item">
              <img
                src="../../../assets/images/calendar-icon.png"
                class="info-date-icon"
              />
              <div class="info-value">{{ result.finalDateFormatted }}</div>
              <div class="info">
                <img
                  src="../../../assets/images/hammer-icon.png"
                  alt="icone pregao eletronico"
                  class="info-pregao-icon"
                />
                {{ result.modalidadeLabel }}
              </div>
            </div>
            <div class="info-tag-locations">
              <div class="info-icon">
                <img
                  src="../../../assets/images/location-icon.png"
                  class="info-icon"
                  alt="localização"
                />
                {{ result.nameBuyer }}
              </div>
              <div class="info-tags">
                <p class="info-tag" *ngIf="result.lowCompetition === true">
                  <img
                    src="../../../assets/images/low-competition-icon.png"
                    alt="icone baixa concorrencia"
                    class="tag-icon"
                  />
                  Baixa concorrencia
                </p>
                <p class="info-tag" *ngIf="result.exclusive === true">
                  <img
                    src="../../../assets/images/exclusive-me.png"
                    alt="icone exclusivo ME"
                  />
                  Exclusivo ME
                </p>
              </div>
            </div>
          </div>
          <div class="card-info-right">
            <a
              [href]="
                'https://www.portaldecompraspublicas.com.br/processos' +
                result.utmUrl
              "
              target="_blank"
              class="action-button"
              >ACESSAR</a
            >
          </div>
        </div>
      </div>
    </ng-container>
    <div class="loading-more-items" *ngIf="!finalPage && isLoading">
      <img
        src="../../../assets/images/loading-mobile.gif"
        alt="icone de carregando mais items"
        class="loading-icon-more-items"
      />
      <p class="loading-text-more-items">Carregando...</p>
    </div>
    <ng-container
      *ngIf="loadedBids.length === 0 && didRequest && !isLoading && payload.page === 1"
    >
      <div class="error-container">
        <div class="error-icon">
          <img
            src="../../../assets/images/no-search-found-icon.png"
            alt="icone pesquisa nao localizada"
          />
        </div>
        <div class="error-content">
          <p class="error-message">Ops! Não achamos nada desta vez.</p>
          <div class="error-info">
            <span class="info-text">Mas não se preocupe, como você se cadastrou no nosso aviso de licitações, assim que surgir uma oportunidade nós notificaremos você! </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="loading-items-container" *ngIf="isLoading && !showItems">
    <img
      src="../../../assets/images/loading-desktop.gif"
      alt="icone de carregando"
      class="loading-icon"
    />
    <p class="loading-text">Carregando...</p>
  </div>
  <div
    class="end-search-container"
    *ngIf="loadedBids.length > 0 && didRequest && !isLoading && finalPage"
  >
    <div class="end-search-icon">
      <img src="../../../assets/images/lupa.png" alt="icone lupa de pesquisa" />
    </div>
    <div class="end-search-content">
      <p class="end-search-message">
        Ops! Parece que chegamos ao fim dos resultados.
      </p>
      <div class="end-search-info">
        <span class="info-text">Sua busca chegou ao fim. Não há mais resultados para exibir.</span>
      </div>
    </div>
  </div>
</div>
