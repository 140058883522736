import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Index1Component } from './index1/index1.component';
import { Index2Component } from './index2/index2.component';

const routes: Routes = [
  {
    path: '',
    component: Index1Component,
  },
  {
    path: 'obrigado-1-aviso-de-licitacoes',
    component: Index2Component,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
